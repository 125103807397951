

.header{
    padding: 24px 0;
    max-width: 82rem;
    margin: 0 auto;
}

.contain-logo{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.name{
    font-size: 1.25rem;
    font-weight: 700;
    color: $special;
    line-height: 1.375rem;

}

.position{
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}

.navigation{
    display: none;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.logo{
    animation: spin 15s linear infinite;
}

@media screen and (min-width: 768px){
    .header{
        padding: 24px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }
    .navigation{
        display: inline-block;
    }
    .section__link-contact{
        color: #020916;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        border-radius: 0.75rem;
        background-color: $special;
        padding: 0.813rem 2rem;
        transition: background-color 0.5s ease-in-out;
        will-change: background-color;
    }

}