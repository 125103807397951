

.image-extra-margin{
    margin-bottom: 1.5rem;
}

.header-after-hours{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.03rem;
    margin: 0 0 0.25rem 0;
}

.paragraph-after-hours{
    color: $grey;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}