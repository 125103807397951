.extra-margin {
    margin-bottom: 7.5rem;
}

.section__header{
    color: $grey;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 90% */
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 1rem;
}

.section__paragraph--special{
    color: white;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.03rem;
    margin-bottom: 2.5rem;
}

.container__skills{
    display: grid;
    gap: 1rem;
}

.container__projects{
    display: grid;
    gap: 1rem;
}

.container__after-hours{
    display: grid;
    gap: 3.75rem;
}

@media screen and (min-width:768px){
    .extra-margin {
        margin-top: 15rem;
    }
    .section__paragraph--special{
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3rem; /* 120% */
        letter-spacing: -0.05rem;
        margin-bottom: 5rem;
    }
    .container__skills{
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .container__projects{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .container__after-hours{
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
    }
}

@media screen and (min-width:1024px){
    .container__after-hours{
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 1rem;
    }
}