.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact__header{
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.04rem;
    margin: 0 0 1rem 0;
}

.contact__paragraph{
    color: $grey;
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2.5rem;
}

.margin-bottom{
    margin-bottom: 2.5rem;
}

.special-size-small{
    width: 1.25rem;
    height: 1.25rem;
}

.contact__paragraph--special{
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
}

.contact-image-container{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background: linear-gradient(155deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.04) 100.02%);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cantainer-contact-list{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width:768px){
    .contact__header{
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: -0.05rem;
    }
    .contact__paragraph{
        font-size:1.5rem;
        line-height: 2rem;
    }
    .margin-bottom{
        margin-bottom: 5rem;
    }
}