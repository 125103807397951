.div-container{
    height: 2.75rem;
    border-bottom: 1px solid #020916;
    background: linear-gradient(155deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.04) 100.02%);
    padding: 1rem;
    display: flex;
    gap: 0.375rem;
}

.article-container {
    padding: 1.25rem;
    border-radius: 0.625rem;
    border: 0.1rem solid #020916;
    background: linear-gradient(155deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.04) 100.02%);
    box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.15) inset;
}

.article-window{
    padding: 0;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #020916;
    backdrop-filter: blur(5px);
}

.circle{
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    opacity: 0.5;
    background-color: $background;
    display: block;

}

.padding{
    padding: 1.25rem;
}

.flex-header{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.small-padding{
    padding: 0 0 1rem 0;
}

.header-card{
    margin:0;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.paragraph-with-flex{
    display: flex;
    gap: 0.125rem;
    padding: 0.25rem 0.5rem;
    background: rgba(156, 163, 175, 0.10);
    align-items: center;
    color: $grey;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    border-radius: 0.25rem;
}

.paragraph-card{
    color: $grey;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    padding-bottom: 1rem;
}

.card-technology{
    display: flex;
    gap: 0.5rem;
    padding-bottom: 2.5rem;
    flex-wrap: wrap;
}

.card-technology-element{
    color: white;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
    padding: 0.25rem 0.5rem;
    background: rgba(156, 163, 175, 0.10);
    border-radius: 0.25rem;
}

@media screen and (min-width:768px){
    .article-container-radius-bigger{
        border-radius: 1.25rem;
    }
}

@media screen and (min-width:1024px){
    .article-container{
        padding: 2.5rem;
    }
    .padding{
        padding: 2.5rem;
    }
    .article-container-remove-padding{
        padding: 0;
    }
}