.article__header{
    border-top: 0.2rem solid $special;
    margin:0;
    padding: 0.75rem 0;
    color: #FFF;
    font-family: DM Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem; /* 133.333% */
    letter-spacing: -0.03rem;
}

.article__image{
    margin-bottom:2.5rem
}

@media screen and (min-width:768px){
    .container-skills-width{
        max-width: 45rem;
    }
    .container-skills-width-bigger{
        max-width: 50rem;
    }
    .article__image{
        margin-bottom:3rem;
    }
}

@media screen and (min-width:1024px){
    .article__image{
        margin-bottom:5rem;
    }
}