

.special__link-change-color:hover{
    background-color: #c2fc5e;
}

.special__link-change-border-color:hover{
    border-color: $special;
}

.section__button{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.section__link{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background-color: $special;
    color: $background;
    border-radius: 0.625rem;
    font-size: 1.25rem;
    line-height: 2rem;
    transition: background-color 0.5s ease-in-out;
    will-change: background-color;
}
.section__link-small{
    color: #020916;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.section__link--border{
    border: 0.125rem solid $special;
}

.special-size{
    width: 1.5rem;
    height: 1.5rem;
}

.section__link--special{
    background-color: $background;
    color: $special;
    border: 0.125rem solid #1F2937;
    transition: border-color 0.4s ease-in-out;
    will-change: border-color;

}

.section__link-special-padding{
    padding: 1rem 1.25rem;
}

.section__link-radius{
    border-radius: 1.5rem;
}

@media screen and (min-width: 768px){
    .button-flex{
        display: flex;
        justify-content: center;
        gap: 0.5rem;
    }
    .section__link{
        padding: 0.875rem 2rem;
    }
    .section__button{
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        flex-wrap: wrap;
    }
    .section__link--biger-radius{
        border-radius: 1.25rem;
    }
    .section__link-special-padding{
        padding: 1rem 1.25rem;
    }
    .section__link-normal{
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
