.image{
    width: 100%;
    margin-bottom: 1.25rem;
    border-radius: 1.25rem;
}



.image-main{
    width: 21.438rem;
    margin-bottom: 1.25rem;
}

.main__header{
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem; /* 133.333% */
    letter-spacing: -0.02rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.section-about__paragraph{
    font-size: 1rem;/* 133.333% */
    color: $grey;
    margin-bottom: 2rem;
    font-weight: normal;
}

.special-color{
    color: $special;
}

@media screen and (min-width: 768px){
    .picture{
        order: 2;
    }
    .about-contain{
        max-width: 47rem;
    }
    .section-about{
        display: flex;
        gap: 4rem;
        padding-top: 7.5rem;
        justify-content: space-between;
        align-items: center;
    }
    .image-main{
        width: 26rem;
    }
    .main__header{
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.25rem; /* 133.333% */
        letter-spacing: -0.06rem;
    }
    .section-about__paragraph{
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
        margin-bottom: 4rem;
    }
}

@media screen and (min-width:1024px){
    .main__header{
        font-size: 3rem;
        line-height: 4rem;
    }
}