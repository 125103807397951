@import '../../node_modules/normalize.css/';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&display=swap');
@import './components/colors.scss';
@import './components/header.scss';
@import './components/section-about.scss';
@import './components/button.scss';
@import './components/section.scss';
@import './components/section-skills.scss';
@import './components/section-projects.scss';
@import './components/section-after-hours.scss';
@import './components/section-contact.scss';

html {
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif;
    scroll-behavior: smooth;
}

*, *:after, *:before{
    box-sizing: border-box;
}

main {
    padding: 0 1rem;
    max-width: 82rem;
}


a {
    color: white;
    text-decoration: none;
}

body {
    background-color: $background;
    color: white;
    display: flex;
    justify-content: center;
}

section{
    max-width: 82rem;
    margin: 0 auto;
    margin-bottom: 80px;
}

p {
    margin: 0;
}


.section__link-big-padding{
    padding: 0.875rem 2rem;
}

ul {
    list-style: none;
    margin: 0;
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
    letter-spacing: -0.018rem;
    padding: 0;
}

ul li{
    padding-bottom: 8px;
}

.section__small{
    font-size: 1rem;
}

.underline{
    text-decoration: underline;
}

@media screen and (min-width: 768px){
    .navigation ul {
        display: flex;
        gap: 2.5rem;
        align-items: center;
    }
    .navigation ul li {
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        padding: 0;
    }
    main{
        padding: 0 4rem;
     }
}
